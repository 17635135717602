import React, { useState, useCallback } from "react"
import debounce from "lodash.debounce"
import { Link } from "gatsby"

// Components
import ItemImage from "./itemImage"
import UnitPrice from "./unitPrice"
import TotalPrice from "./totalPrice"
import RemoveItem from "./removeItem"
import NumericInput from "./numericInput"

const LineItem = ({ item, checkout, loading, updateLineItem }) => {
  const [quantity, setQuantity] = useState(item.quantity)

  const uli = debounce(
    value => updateLineItem(checkout.id, item.id, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = useCallback(value => uli(value), [])

  const handleQuantityChange = value => {
    if (value !== "" && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  return (
    <div
      className="grid md:grid-cart gap-4 p-4 "
      style={{ background: "#f7fbff" }}
    >
      <div className="flex">
        <Link to={`/presets/${item?.variant?.product?.handle}`}>
          <ItemImage item={item.variant} />
        </Link>
        <div className="pl-4 pt-2">
          <Link to={`/presets/${item?.variant?.product?.handle}`}>
            <p className="text-lg font-semibold">{item?.title}</p>
          </Link>
          <RemoveItem item={item} disabled={loading} />
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-line-item  gap-10">
        <div className="hidden md:block">
          <UnitPrice item={item?.variant} />
        </div>
        <div>
          <NumericInput
            disabled={loading}
            value={quantity}
            aria-label="Quantity"
            onIncrement={doIncrement}
            onDecrement={doDecrement}
            onChange={e => handleQuantityChange(e.currentTarget.value)}
          />
        </div>
        <div>
          <TotalPrice
            item={item?.variant}
            quantity={quantity}
            discounts={
              item?.discountAllocations?.length
                ? item?.discountAllocations[0]
                : null
            }
          />
        </div>
      </div>
    </div>
  )
}

export default LineItem

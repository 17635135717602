import React, { useMemo } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"

const ItemImage = ({ item }) => {
  const variantImage = {
    ...item.image,
    originalSrc: item.image.src,
  }

  const prod = item.product.handle

  const image = useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "cover",
        width: prod === "master-bundle-192-lr-mobile-presets-course" ? 200 : 80,
        height: 112,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )

  return (
    <div>
      {image && (
        <GatsbyImage
          key={variantImage.src}
          image={image}
          alt={variantImage.altText ?? "Lightroom Preset product banner"}
        />
      )}
    </div>
  )
}

export default ItemImage

import React from "react"

const NumericInput = ({ disabled, onIncrement, onDecrement, ...props }) => {
  return (
    <div>
      <p className="mb-3 text-sm">Količina</p>
      <div className="bg-white border w-max">
        <button
          className="px-4 py-2 hover:bg-gray-50 transition duration-200 ease-in"
          onClick={onDecrement}
          disabled={disabled}
        >
          -
        </button>
        <input
          disabled={disabled}
          type="numeric"
          {...props}
          className="py-2 text-center border-l border-r rounded-none focus:outline-none"
          style={{ width: 40 }}
          readOnly
        />

        <button
          className="px-4 py-2 hover:bg-gray-50 transition duration-200 ease-in"
          onClick={onIncrement}
          disabled={disabled}
        >
          +
        </button>
      </div>
    </div>
  )
}

export default NumericInput

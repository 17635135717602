import React from "react"

import { formatPrice } from "../../../utils/format-price"

const UnitPrice = ({ item }) => {
  const price = formatPrice(
    item.priceV2.currencyCode,
    Number(item.priceV2.amount)
  )

  return (
    <div>
      <p className="mb-3 text-sm">Cena</p>
      <p className="font-semibold">{price}</p>
    </div>
  )
}

export default UnitPrice

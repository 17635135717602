import React, { useContext } from "react"

// Context
import { StoreContext } from "../../../context/store-context"

const RemoveItem = ({ item, disabled }) => {
  const { removeLineItem, checkout } = useContext(StoreContext)

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }
  return (
    <button
      disabled={disabled}
      className="underline mt-2 hover:text-red-600 transition duration-200 ease-in-out"
      onClick={handleRemove}
      style={{ fontSize: 15 }}
    >
      Odstrani
    </button>
  )
}

export default RemoveItem

import React from "react"

import { formatPrice } from "../../../utils/format-price"

const TotalPrice = ({ item, quantity, discounts }) => {
  const subtotal = formatPrice(
    item.priceV2.currencyCode,
    Number(item.priceV2.amount) * quantity
  )

  const discountedPrice = formatPrice(
    item.priceV2.currencyCode,
    parseFloat(subtotal) - parseFloat(discounts?.allocatedAmount.amount)
  )

  return (
    <div className="text-right h-full grid sm:h-auto">
      <p className="mb-3 text-sm">Skupaj</p>
      {discounts ? (
        <div>
          <p className="line-through">{subtotal}</p>
          <p className="text-red-600 ml-3 font-semibold">{discountedPrice}</p>
        </div>
      ) : (
        <p className="font-semibold">{subtotal}</p>
      )}
    </div>
  )
}

export default TotalPrice
